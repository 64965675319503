/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import { ContentPageLayout } from '@components/Layout';
import { PaymentHistoryTable, MonthlyPaymentTable, DepositInformationTable } from '@components/Table';
import { ReadOnlyForm } from '@components/Form';
import { observer } from 'mobx-react';
import { TextSection } from '@components/TextSection';
import { Row, Col } from '@components/Grid';
import { Hr, Text, Link } from '@primitives';
import { PaymentStatusBlock } from '@components/PaymentStatusBlock';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { BlueBox, ShortcutBox } from '@components/BlueBox';
import { until } from '../../styles/theme-utils';
import { PAYMENTS } from '@constants';
import { useStores } from '@stores';
import { RightOfOccupancyTable } from '@components/Table/hocs/RightOfOccupancyTable';

interface MyInformationPaymentInformationPageProps {
  data: IMarkdownData;
}


const PaymentHistoryContainer = styled.section`
  display: grid;
  grid-template-columns: 66% 33%;
  column-gap: 2rem;
  width: calc(100% - 2rem);
  ${until('md', `
    display: block;
    width: 100%;
  `)};
`;

const GreyBox = styled(BlueBox)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  ${until('md', `
    margin-bottom: 2rem;
  `)};
`;


// eslint-disable-next-line max-len
const MyInformationPaymentInformationPage: FC<MyInformationPaymentInformationPageProps> = observer(({
  data: {
    markdownRemark: { frontmatter: { blocks: texts } },
  },
}) => {
  const {
    agreementStore: { selectedAgreement, selectedAgreementId, state: agreementState },
    paymentStore: {
      getPaymentsPageData, currentCharges: charges, currentPayments, currentRightOfOccupancyPayments, state: paymentState,
      currentDeposits,
    },
    commonDataStore: { getContacts, state: commonDataState, contactInformation },
  }: {
    paymentStore: IPaymentStore;
    agreementStore: IAgreementStore;
    commonDataStore: ICommonDataStore;
  } = useStores();


  const isAgreementLoading = agreementState === 'Loading';
  const isPaymentsLoading = paymentState === 'Loading';
  const isCommonDataLoading = commonDataState === 'Loading';
  const [contactBlocks, setContactBlocks] = useState<IContactInformation[]>([]);

  useEffect(() => {
    if (selectedAgreementId && paymentState === 'Idle') getPaymentsPageData(selectedAgreementId);
  }, [selectedAgreementId]);

  useEffect(() => {
    if (!isCommonDataLoading && !contactInformation) {
      getContacts();
    }
    if (contactInformation && !contactBlocks.length) {
      const selectedBlocks = contactInformation.filter((block: IContactInformation) => {
        return block.contact_type === 'rentsupervision' || block.contact_type === 'debtcollection';
      });
      setContactBlocks(selectedBlocks);
    }
  }, [selectedAgreement, contactInformation]);

  const upcomingCharges = charges?.find((charges: ICharge) => moment(charges.start_date).isAfter(new Date()));
  const currentCharges = charges?.find((charges: ICharge) => {
    return moment(charges.start_date).isSame(new Date()) || moment(charges.start_date).isBefore(new Date());
  });

  // eslint-disable-next-line max-len
  const getTexts = (id: string) => (texts ? texts.find((block) => block.id === id) : null);

  const getContactBoxLink = (url: string, type?: 'tel' | 'mailto') => {
    const pre = type ? `${type}:` : '';
    return <Text fontWeight="600"><Link fontWeight="600" href={`${pre}${url}`}>{url}</Link></Text>
  };

  return (
    <ContentPageLayout wideContent>
      <TextSection title={<M id='fields.label.paymentInformation.title'/>} text={<M id='fields.label.paymentInformation.text'/>} />
      <Row columns={2}>
        <Col>
          <PaymentStatusBlock />
          {charges && (
            <GreyBox
              mt="2"
              loading={isAgreementLoading}
              title={(
                <M
                  id="pages.myInformation.paymentsPage.monthlyPayment"
                  // @ts-ignore
                  values={{ sum: charges && charges[0] && charges[0].total_price }}
                />
              )}
            >
              <Text fontWeight="500">
                <M id="pages.myInformation.paymentsPage.monthlyPaymentDueDate" values={{ day: selectedAgreement && selectedAgreement.due_day }} />
              </Text>
            </GreyBox>
          )}
        </Col>
        <Col>
          <Text mb="2" fontWeight="500" color="black"><M id="pages.myInformation.paymentsPage.informationForPayment" /></Text>
          <ReadOnlyForm
            loading={isAgreementLoading}
            id="payments"
            values={selectedAgreement}
            fields={PAYMENTS}
            styles={['singleColumn']}
          />
        </Col>
      </Row>
      <Hr />

      <TextSection level="3" title={<M id='fields.label.paymentHistory.title'/>} />

      <PaymentHistoryContainer>
        <PaymentHistoryTable data={currentPayments} />
        <div>
          {contactBlocks && contactBlocks.map((box, i: number) => (
            <BlueBox key={box.title} mb={i !== contactBlocks.length - 1 ? '2' : '0'} title={box.title}>
              <Text mb="1">{box.description}</Text>
              {box.name && <Text fontWeight="600">{box.name}</Text>}
              {box.phone_number && getContactBoxLink(box.phone_number, 'tel')}
              {box.email && getContactBoxLink(box.email, 'mailto')}
              {box.homepage_url && getContactBoxLink(box.homepage_url)}
            </BlueBox>
          ))}
        </div>
      </PaymentHistoryContainer>
      <Hr />
      
      {!isPaymentsLoading && currentRightOfOccupancyPayments && (
        <>
          <TextSection level="3" title={<M id='fields.label.rightOfOccupancyPayments.title'/>} />
          <RightOfOccupancyTable
            data={currentRightOfOccupancyPayments}
            loading={isPaymentsLoading}
          />
          <Text mt="3" color="black" fontWeight="500"><M id="pages.myInformation.paymentsPage.rooPaymentDescription" /></Text>
          <Hr />
        </>
      )}

      {!isPaymentsLoading && currentCharges && (
        <>
          <TextSection level="3" title={<M id='fields.label.monthlyPayment.title'/>} />
          <MonthlyPaymentTable
            // @ts-ignore
            data={currentCharges}
            loading={isPaymentsLoading}
            id="monthlyPayment"
          />
          <Hr />
        </>
      )}

      {!isPaymentsLoading && upcomingCharges && (
        <>
          <TextSection level="3" title={<M id='fields.label.upcomingPayment.title'/>} />
          <MonthlyPaymentTable
            loading={isPaymentsLoading}
            id="upcomingPayment"
            // @ts-ignore
            data={upcomingCharges}
          />
          <Hr />
        </>
      )}

      {!isPaymentsLoading && currentDeposits && !!currentDeposits.length && (
        <>
          <TextSection level="3" title={<M id='fields.label.depositInformation.title'/>} />
          <DepositInformationTable data={currentDeposits} />
        </>
      )}
      <ShortcutBox />
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query MyInformationPaymentsQuery {
  markdownRemark(frontmatter: {path: {eq: "/me/payment-information/"}, paymentHistoryBoxes: {}}) {
    frontmatter {
      title
      path
      blocks {
        text
        id
        title
      }
      paymentHistoryBoxes {
        text
        title
      }
    }
  }
}
`;

export default MyInformationPaymentInformationPage;
