import React, { useMemo } from 'react';
import { Table } from '@components/Table';
import { FormattedDate as Date, useIntl } from 'gatsby-plugin-intl';

interface TableProps {
  data: any;
  loading: boolean;
}

const RightOfOccupancyTable: React.FC<TableProps> = ({
  data: { original_payment, current_payment, original_payment_date },
}) => {
  const { formatMessage } = useIntl();

  const arrData = [{
    type: formatMessage({ id: 'pages.myInformation.paymentsPage.rooPayment' }),
    unitPrice: original_payment,
    units: 1,
    price: original_payment,
    starts: original_payment_date,
  }, {
    type: formatMessage({ id: 'pages.myInformation.paymentsPage.rooPaymentIndex' }),
    unitPrice: current_payment,
    units: 1,
    price: current_payment,
    starts: ''
  }];

  const columns = useMemo(() => [{
    accessor: 'type',
    Header: '',
    width: '33%',
  }, {
    accessor: 'price',
    Header: 'sum',
    width: '33%',
    minWidth: '200px',
  }, {
    accessor: 'starts',
    Header: 'starts',
    width: '33%',
    Cell: ({ cell }: any) => (cell.value ? <Date value={cell.value} /> : ''),
  }], []);

  return <Table id="roo" columns={columns} data={arrData} headerColumn />;
};

export { RightOfOccupancyTable };
