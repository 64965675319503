import React from 'react';
import {
  Text, Link, Box, Heading, Spinner,
} from '@components/Primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import styled from 'styled-components';
// @ts-ignore
import Smiley from '@icons/green_smiley.svg';
// @ts-ignore
import Exclamation from '@icons/exclamation.svg';
import { ShadowBlock } from '@primitives';
import { until } from '@styles';
import { useStores } from '@stores';
import { observer } from 'mobx-react';
import { IAgreementStore } from '../../types';
import { screens } from '../../styles/theme';
import { PATHS } from '../../constants/nav-items';

const PaymentStatus = styled(Box) <{ colorScheme: 'green' | 'red' }>`
  background-color: ${(props) => props.colorScheme === 'green' ? props.theme.colors.lightGreen : props.theme.colors.rose};
  border: 1px solid ${(props) => props.colorScheme === 'green' ? props.theme.colors.teal : props.theme.colors.errorRed};
  min-width: 125px;
  height: 55px;
  padding: 0.25rem 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${screens.lg}px) and (min-width: 0) {
    margin-left:0;
    margin-top: 1rem;
  }
  svg {
    width: 36px;
    height: 36px;
    position: absolute;
    left: -18px;
  }
`;

const BlockHeading = styled(Heading) <{ textAlign: 'center' | 'left' }>`
  text-align: ${(props) => props.textAlign};
`;

const IconContainer = styled(Box) <{ colorScheme: 'green' | 'red' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  position: absolute;
  left: -15px;
  border-radius: 32px;
  background-color: ${(props) => props.colorScheme === 'green' ? props.theme.colors.teal : props.theme.colors.messageRed};
  svg {
    max-width: 16px;
    max-height: 16px;
    position: static;
  }
  ${until('lg', `
    left: 15px;
  `)}
`;

const TextContainer = styled(Box)`
  display: flex;
  > :first-child {
    flex: 2 1 100%;
  }
  @media screen and (max-width: ${screens.lg}px) and (min-width: 0) {
    display: block;
  }
`;

const Container = styled(ShadowBlock)`
  position: relative;
  .spinner {
    position: absolute;
    left: calc(50% - 0.75rem);
    top: calc(50% - 0.75rem);
  }
  ${until('lg', `
    padding: 1rem;
  `)}
`;

interface IPaymentStatusBlock {
  showLink?: boolean;
}

const PaymentStatusBlock: React.FC<IPaymentStatusBlock> = observer(({ showLink }) => {
  const { agreementStore: { selectedAgreement, state } }: { agreementStore: IAgreementStore } = useStores();
  const isPaid = selectedAgreement && selectedAgreement?.balance >= 0 && !selectedAgreement?.transferred_to_debt_collection;
  const isInDebtCollection = !!selectedAgreement?.transferred_to_debt_collection;
  const hasWarning = !isPaid || isInDebtCollection;

  const getStatusText = () => {
    if (!selectedAgreement) return;
    if (!isPaid && !isInDebtCollection) return 'paymentDue';
    if (isInDebtCollection) return 'paymentStatusDebt';
    return 'paymentsPaid';
  };



  const renderIcon = () => {
    if (!selectedAgreement) return;
    const Icon = hasWarning ? Exclamation : Smiley;
    return <IconContainer colorScheme={hasWarning ? 'red' : 'green'}><Icon /></IconContainer>
  };

  const infoTextValues = {
    strong: (text: string) => <strong>{text}</strong>,
  };

  return (
    <Box>
      <Container>
        {state === 'Loading' && <Spinner color="mainBlue" />}
        {selectedAgreement && (
          <>
            <BlockHeading mb="1" level="3" textAlign={isInDebtCollection ? 'center' : 'left'}>
              {/* @ts-ignore */}
              <M id={`common.paymentStatus.${getStatusText()}`} values={{ ...infoTextValues, ...{ balance: selectedAgreement.balance } }} />
            </BlockHeading>
            <TextContainer>
              {!isInDebtCollection && (
                // @ts-ignore
                <Text> <M id="common.paymentStatus.infoText" values={infoTextValues} /></Text>
              )}
              <PaymentStatus ml={isInDebtCollection ? '0' : '2'} colorScheme={hasWarning ? 'red' : 'green'}>
                {renderIcon()}
                {!isInDebtCollection && <Text fontSize="md">{`${selectedAgreement.balance}€`}</Text>}
                {isInDebtCollection && <Text fontSize="md"><M id="common.paymentStatus.paymentDebtCollection" /></Text>}
              </PaymentStatus>
            </TextContainer>
          </>
        )}
      </Container>
      {
        showLink && (
          <Link pt="1" to={PATHS.myInformation.paymentInformation} arrow>
            <Text uppercase fontWeight="400">
              <M id="pages.indexPage.paymentHistory" />
            </Text>
          </Link>
        )
      }
    </Box>
  );
});

export { PaymentStatusBlock };
